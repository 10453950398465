<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <div>
          <AppBar :drawer="false"/>
        </div>
        <v-container id="topo">
          <a style="text-decoration: none;color: #70445E;" @click.stop="voltar()">VOLTAR</a>
          <v-row class="mt-5">
            <v-col cols="12" class="text-h6" style="color: #70445E;">EN DEHORS – Edição Setembro/Outubro</v-col>
            <v-col cols="12">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
              <v-expansion-panels multiple accordion v-model="panel">
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – O Chicote da Arte: 32 fouettés</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;No Brasil, dia primeiro de setembro é data em que se comemora o Dia da Bailarina. Na verdade, a escolha acompanha o calendário do Dia do Profissional da Educação Física e partilharei com vocês uma discussão que me acompanhou (e incomodou) por toda vida.</p>
                      <p>&nbsp;Vejamos… arte e atividade física são coisas distintas, ainda que a arte da dança se faça com o corpo e no corpo. A faculdade de educação física não forma bailarinos. Aprendi ballet clássico com quem dançava ballet clássico e não apenas com quem havia estudado a teoria. Ser bailarina implica em (e supera) conhecer história da dança, enredo dos repertórios, boas noções de anatomia, e a experimentação dessa cultura em movimento no corpo. Assim foi minha formação.</p>
                      <p>&nbsp;Por isso, reluto em aceitar bem quando vejo um mercado em torno da dança contratando “bailarinos(as)” que não o são; ou, ainda, promovendo ensaios fotográficos com trajes e sapatilhas de ballet utilizados de modo equiparado a uma fantasia, via de regra resultando imagens distorcidas e de mau gosto.</p>
                      <p>&nbsp;Lembro com carinho das primeiras bolhas, das unhas que perdi, e de ter alguma satisfação com alongamentos doloridos: “Se não está doendo, não está funcionando”, eu ouvia da professora. Trocando em miúdos a arte é um atravessamento, sentimos na carne.</p>
                      <p>&nbsp;É inesquecível a cena da mestra Toshi Kobayashi parada diante de mim, de braços abertos, repetindo: “Fria! Bate cabeça!” para me arrancar 16 fouettés. Para os leigos, esse último é o nome de um giro técnico bastante específico, um tipo de pirueta, das mais difíceis no ballet. E “bater cabeça”é a forma usual de designar o movimento rápido de rotação da cabeça que orienta e confere eficiência ao giro.</p>
                      <p>&nbsp;No final dos grandes ballets de repertório já são esperados os tais giros chamados fouettés. É a hora da solista mostrar a que veio! A primeira bailarina a sequenciar esse giro sobre uma perna só na quantidade de 32 repetições foi a italiana Pierina Legnani, na Coda do Ballet Cinderela de Lev Ivanov e Enrico Cecchetti, em dezembro de 1863. Foi uma surpresa para o público. Pierina foi também a primeira a ser intitulada “Primma Ballerina Assoluta”, título antigo atribuído às bailarinas que se destacavam.</p>
                      <p>&nbsp;Pierina abriu caminho para que outras a seguissem. Mostrou que era possível e fez com que as próximas trabalhassem para alcançar a marca dos 32 giros. A tradução do termo fouetté é “chicoteado”, uma referência ao movimento de lançamento que a perna faz durante o giro, desenhando uma chicotada no ar.</p>
                      <p>&nbsp;Voltando ao meu ponto de partida: a bailarina é aquela que conhece o chicote; é aquela para quem ele diz algo; é a que experienciou essa cultura. Arte não é teoria. Arte se forja no chicote da arte: dói e é sublime!</p>
                      <p>&nbsp;A mestra Toshi (que tentava me arrancar os fouettés), falecida em 2016, formou muitos bailarinos, foi jurada e integrante do Conselho Consultivo do Festival de Dança de Joinville e ministrou cursos para bailarinos da Cia Jovem da Escola do Teatro Bolshoi, única unidade fora da Rússia – em Joinville. Tinha pouca altura, mas se agigantava no vigor com que conduzia a extração da dança na melhor entrega do corpo de bailarinos e bailarinas que ensinava. Dona Toshi (como a conheci de pequena) tinha olhos de fouetté… uma verdadeira chicotada aquele olhar.</p>
                      <p>&nbsp;É dessa transmissão que falo. É cheia de vida, de história, do imponderável que a arte proporciona e que não se apreende nos bancos de uma faculdade. Faz parte da cultura, daquilo que se cultiva e que é transmitido historicamente por meio de códigos e padrões de significados …tradição.</p>
                      <p>&nbsp;A palavra vem do latim traditio e significa entregar, passar adiante. A dança faz elo entre as gerações. Há um processo de escolha, apropriação e identidade. É preciso ter algo em si, para poder entregar.</p>
                      <p>&nbsp;Dentro dessa lógica, cada povo ou nação singulariza uma traço próprio que expressa em (suas) danças. O tango argentino, o flamenco espanhol, o samba brasileiro, etc.</p>
                      <p>&nbsp;O universo é vasto e a diversidade se impõe, de modo que um povo não dança uma só dança. Aqui, em terras tupiniquins de onde escrevo, vamos do ballet clássico, passando pelo contemporâneo, jazz, stiletto, axé, funk, samba, e há muito mais. Só em matéria de samba, podemos destacar o choro, o samba percussivo, o samba de roda, o samba no pé, o samba de gafieira, e tantos outros.</p>
                      <p>&nbsp;A bailarina Helen Ribeiro, que quando da última edição da nossa revista estava imersa em estratégias de arrecadação de fundos para poder levar sua gafieira ao Festival de Dança de Joinville de julho, conseguiu os valores necessários para estar lá. Ela é nossa entrevistada dessa vez e conta um pouco do que foi a experiência, bem como o que é ser bailarina no Brasil.</p>
                      <p>&nbsp;Convido-os a deixarem que as palavras façam a condução e que se deixem dançar e levar pelo texto, numa imersão até os bastidores dessa arte no nosso país na contemporaneidade, sob a perspectiva de quem trabalha por ela. O roteiro é de uma menina que sonhou, que se arriscou em um universo rigoroso quanto às exigências de um corpo padrão (Helen conhece o chicote!), que abraçou a identidade brasileira do samba de gafieira e que segue realizando.</p>
                      <p>&nbsp;Antes, contudo, às companheiras bailarinas, deixo uma breve mensagem: Dançar é uma experiência e desejo um feliz primeiro de setembro (com sabor de todo dia) para todas de nós que nos permitimos vivê-la: que não nos faltem eixo, linhas alongadas, colo de pé, e que resistam as unhas!</p>
                      <p><img loading="lazy" class="alignnone size-medium wp-image-17478" src="https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-300x234.jpg" alt="" width="300" height="234" srcset="https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-300x234.jpg 300w, https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-1024x797.jpg 1024w, https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-768x598.jpg 768w, https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-1170x911.jpg 1170w, https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE.jpg 1242w" sizes="(max-width: 300px) 100vw, 300px"></p>
                      <p><a href="https://www.instagram.com/reel/CfRMqYNARkb/?igshid=YmMyMTA2M2Y%3D" role="link">Clique aqui para assistir</a></p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Pas De Deux</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p style="text-align: center;"><img loading="lazy" class="alignnone  wp-image-17484" src="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-300x300.png" alt="" width="213" height="213" srcset="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-300x300.png 300w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-150x150.png 150w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO.png 612w" sizes="(max-width: 213px) 100vw, 213px"></p>
                      <blockquote>
                      <p style="text-align: center;"><span style="font-size: 16px;"><strong>Helen Ribeiro <img loading="lazy" class="alignnone  wp-image-17316" src="https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-300x210.png" alt="" width="31" height="22" srcset="https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-300x210.png 300w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-1024x717.png 1024w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-768x538.png 768w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-1536x1075.png 1536w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-2048x1434.png 2048w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-1170x819.png 1170w" sizes="(max-width: 31px) 100vw, 31px"></strong></span></p>
                      <p style="text-align: center;"><span style="font-size: 16px;"><strong>Bailarina, Professora, Coreógrafa, Pesquisadora Licenciada em Dança UFMG</strong></span></p>
                      </blockquote>
                      <p>&nbsp;Iniciou seus estudos na Dança em 2002 com a dança do ventre e, em seguida, começou a fazer aulas de ballet em 2005 do qual continua até hoje. Já participou de vários espetáculos de Balé de repertório, além de ter sido bailarina principal nos espetáculos como “Raymonda” e “Harlequinade”. Participou diversas vezes do Festival de Dança em Joinville e de vários cursos e concursos, sendo premiada em dois deles. Trabalhou na Banda Just Beat It que fazia um tributo a Michael Jackson, depois fez parte da banda baile Bloco Show e, recentemente, foi integrante da Banda e Bloco de Carnaval Baianas Ozadas e Grupo de Dança Unibhfit. Já dançou no palco com nomes como Benjamim Abras Magary Lord, Moraes Moreira, Milena Jardim, entre outros. Já fez várias aulas, oficinas e cursos de Balé Clássico, Contemporâneo, Jazz, Vogue, Danças Urbanas, Dança de salão, Jazz Funk, Danças Populares e Ritmos.</p>
                      <p>&nbsp;Além de bailarina, atua como professora de dança, ensaiadora, personal dancer e coreografa ensaiando valsas de 15 anos, casamento, espetáculos de ballet e eventos em geral.</p>
                      <p>&nbsp;Atualmente faz aula de balé clássico, jazz funk (dança comercial), forró, samba, tango, bolero, samba e salsa. Trabalha como bailarina comercial dançando em desfiles de moda, blocos de carnaval, shows, projetos sociais, vídeo clipes (“Deus e o Diabo “-Das Quebradas”“.), escolas, hospitais e lares de idosos. Formada em Licenciatura em Dança na UFMG, tendo estudado várias técnicas corporais e abordagens somáticas, tendo desenvolvido uma pesquisa específica com Dança e terceira idade. Promove um projeto social intitulado “Bailarinando por sorrisos” em instituições com criança e idosos.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><strong><span style="font-size: 32px; color: #993300;">1</span></strong></p>
                      <p><strong><span style="color: #ffcc00;">THE BARD</span></strong> Como a dança entrou na sua vida e o que significa o festival de Joinville para você?</p>
                      <p><span style="color: #993300;"><strong>HELEN RIBEIRO</strong></span> A dança surgiu na minha vida desde que eu era criança. Sempre gostei de dançar e criar. Juntava minha família aos domingos e cobrava ingresso de R$ 0,50 para me assistirem apresentar. Comecei com aulas regulares de dança do ventre, por conta da novela “O Clone” e, depois, aos 10 anos, iniciei o ballet clássico, onde permaneço até os dias de hoje.</p>
                      <p>A minha escola sempre fazia excursões para Joinville e, aos 14 anos, pedi para ir. Era meu presente de 15 anos. Queria estar com meu grupo e vivenciar todas as experiências possíveis. Nessa época, estava muito apaixonada e envolvida com o ballet.</p>
                      <p>Depois disso, fui mais algumas vezes, porém sempre para fazer cursos e assistir danças. Nunca cogitei dançar nos palcos. Achava impossível ser selecionada no meio de tantas pessoas boas.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><strong><span style="font-size: 32px; color: #993300;">2</span></strong></p>
                      <p><strong><span style="color: #ffcc00;">THE BARD </span></strong>Quantas vezes você foi até o evento?</p>
                      <p><strong><span style="color: #993300;">HELEN RIBEIRO </span></strong>É a quinta vez que vou ao Festival de Joinville. Sendo a primeira vez que vou para dançar nos palcos.</p>
                      <p>Todas as demais vezes fui muito focada no ballet e, hoje, vou com a ideia mais ampla de aproveitar tudo, fazer cursos em novos estilos, e dançar o samba que é uma vertente da dança de salão que é uma das áreas em que sigo investindo.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #993300; font-size: 32px;"><strong>3</strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>THE BARD</strong></span> Qual a sensação de ser selecionada e quais as dificuldades de uma jovem artista para comparecer no evento?</p>
                      <p><strong><span style="color: #993300;">HELEN RIBEIRO </span></strong>A sensação é de que você é boa e capaz e as pessoas acreditam nisso, então: que tal você acreditar também?!</p>
                      <p>Por ter toda base clássica e fazer ballet praticamente a vida toda, sempre que ia em Joinville sempre estava com olhar exclusivo para essa modalidade (o ballet). No entanto, por ser uma dança para qual de fato não tenho tantas facilidades físicas, sempre achei que nunca seria aprovada (há muita gente boa e é uma dança muito restrita para determinados corpos).</p>
                      <p>Hoje, tendo a dança de salão como uma das minhas áreas de atuação, acreditei ter mais chances, por ser uma dança popular em que não há tantas restrições para os corpos.</p>
                      <p>Sendo assim, resolvi enviar o vídeo e, como o resultado demorou para sair, se tornou menor o tempo para juntar dinheiro para ir ao festival.</p>
                      <p>Então, a grande dificuldade, enquanto artista jovem e autônoma, é arrecadar dinheiro que custeie o básico da viagem, comida, hospedagem e avião.</p>
                      <p>Por ser um festival grande, tradicionalmente realizado em julho, período de férias, tudo se torna três vezes mais caro. Como artista e professora, ainda não alcancei o salário que mereço e que me permita viver tranquila. Principalmente, após ter ficada um tempo sem poder trabalhar pela pandemia. A classe artista foi muito afetada e ainda estou buscando espaços e oportunidades para fazer o que tanto amo: trabalhar com Dança.</p>
                      <p>Então, corri contra o tempo para juntar a grana para viajar.</p>
                      <p>Eu e meu partner implementamos várias formas de arrecadação: rifa, workshop, vaquinha e apresentações em baile.</p>
                      <p>As pessoas aderiram muito e nos ajudaram bastante. Cada um ajudando um pouquinho e dando muito apoio. Estamos muitos gratos.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #993300;"><strong><span style="font-size: 32px;">4</span></strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>THE BARD</strong></span> Como é seu trabalho com dança? Você pode falar um pouco da sua atuação junto a idosos?</p>
                      <p><span style="color: #993300;"><strong>HELEN RIBEIRO </strong></span>Sou artista, dançarina, bailarina, professora, coreógrafa, ensaiadora e pesquisadora.</p>
                      <p>Tenho diversas áreas de atuação.</p>
                      <p>Danço em eventos, shows, banda-baile, banda de carnaval (Baianas Ozadas), trabalho como personal dancer, coreografo para grupos, valsas de casamento e de debutante (15 anos), solistas e escolas de dança.</p>
                      <p>Trabalho como ensaiadora de coreografias de escola e pessoas físicas.</p>
                      <p>E, por fim, sou professora de ballet clássico para iniciantes e corpo de baile no ballet Yara Araújo, em Belo Horizonte, que é a escola em que me formei e minha família do coração. Estou junto com eles desde 2005 e, hoje, além de aluna, também sou professora.</p>
                      <p>Atualmente, também dou aula de dança para terceira idade em ILPS (instituições de longa permanência para idosos. É um trabalho democrático por trazer uma dança social e possível para todos os corpos.</p>
                      <p>Por ser formada em Licenciatura em Dança pela UFMG, desenvolvi meu TCC sobre dança e idosos, pesquisa que levo adiante nos dias atuais.</p>
                      <p><img loading="lazy" class="alignnone size-medium wp-image-17485" src="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-295x300.jpg" alt="" width="295" height="300" srcset="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-295x300.jpg 295w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-1007x1024.jpg 1007w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-768x781.jpg 768w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-1170x1190.jpg 1170w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01.jpg 1242w" sizes="(max-width: 295px) 100vw, 295px"></p>
                      <p><img loading="lazy" class="alignnone size-medium wp-image-17486" src="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-300x163.jpg" alt="" width="300" height="163" srcset="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-300x163.jpg 300w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-1024x556.jpg 1024w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-768x417.jpg 768w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-1170x635.jpg 1170w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA.jpg 1242w" sizes="(max-width: 300px) 100vw, 300px"></p>
                      <p><a href="https://www.instagram.com/reel/ChFE9figjk0/?igshid=YmMyMTA2M2Y%3D" role="link">Clique aqui para assistir</a></p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              </v-card>
              <div style="margin-bottom: 25vh;">
                <span><a class="linkGaleria" @click="goTop()">Topo</a></span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <div class="container" id="topo">
          <div>
            <a style="text-decoration: none;color: #70445E;" @click.stop="voltar()">VOLTAR</a>
          </div>
          <span cols="12" class="text-h6" style="color: #70445E;">EN DEHORS – Edição Setembro/Outubro</span>
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-expansion-panels multiple accordion v-model="panel">
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – O Chicote da Arte: 32 fouettés</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;No Brasil, dia primeiro de setembro é data em que se comemora o Dia da Bailarina. Na verdade, a escolha acompanha o calendário do Dia do Profissional da Educação Física e partilharei com vocês uma discussão que me acompanhou (e incomodou) por toda vida.</p>
                      <p>&nbsp;Vejamos… arte e atividade física são coisas distintas, ainda que a arte da dança se faça com o corpo e no corpo. A faculdade de educação física não forma bailarinos. Aprendi ballet clássico com quem dançava ballet clássico e não apenas com quem havia estudado a teoria. Ser bailarina implica em (e supera) conhecer história da dança, enredo dos repertórios, boas noções de anatomia, e a experimentação dessa cultura em movimento no corpo. Assim foi minha formação.</p>
                      <p>&nbsp;Por isso, reluto em aceitar bem quando vejo um mercado em torno da dança contratando “bailarinos(as)” que não o são; ou, ainda, promovendo ensaios fotográficos com trajes e sapatilhas de ballet utilizados de modo equiparado a uma fantasia, via de regra resultando imagens distorcidas e de mau gosto.</p>
                      <p>&nbsp;Lembro com carinho das primeiras bolhas, das unhas que perdi, e de ter alguma satisfação com alongamentos doloridos: “Se não está doendo, não está funcionando”, eu ouvia da professora. Trocando em miúdos a arte é um atravessamento, sentimos na carne.</p>
                      <p>&nbsp;É inesquecível a cena da mestra Toshi Kobayashi parada diante de mim, de braços abertos, repetindo: “Fria! Bate cabeça!” para me arrancar 16 fouettés. Para os leigos, esse último é o nome de um giro técnico bastante específico, um tipo de pirueta, das mais difíceis no ballet. E “bater cabeça”é a forma usual de designar o movimento rápido de rotação da cabeça que orienta e confere eficiência ao giro.</p>
                      <p>&nbsp;No final dos grandes ballets de repertório já são esperados os tais giros chamados fouettés. É a hora da solista mostrar a que veio! A primeira bailarina a sequenciar esse giro sobre uma perna só na quantidade de 32 repetições foi a italiana Pierina Legnani, na Coda do Ballet Cinderela de Lev Ivanov e Enrico Cecchetti, em dezembro de 1863. Foi uma surpresa para o público. Pierina foi também a primeira a ser intitulada “Primma Ballerina Assoluta”, título antigo atribuído às bailarinas que se destacavam.</p>
                      <p>&nbsp;Pierina abriu caminho para que outras a seguissem. Mostrou que era possível e fez com que as próximas trabalhassem para alcançar a marca dos 32 giros. A tradução do termo fouetté é “chicoteado”, uma referência ao movimento de lançamento que a perna faz durante o giro, desenhando uma chicotada no ar.</p>
                      <p>&nbsp;Voltando ao meu ponto de partida: a bailarina é aquela que conhece o chicote; é aquela para quem ele diz algo; é a que experienciou essa cultura. Arte não é teoria. Arte se forja no chicote da arte: dói e é sublime!</p>
                      <p>&nbsp;A mestra Toshi (que tentava me arrancar os fouettés), falecida em 2016, formou muitos bailarinos, foi jurada e integrante do Conselho Consultivo do Festival de Dança de Joinville e ministrou cursos para bailarinos da Cia Jovem da Escola do Teatro Bolshoi, única unidade fora da Rússia – em Joinville. Tinha pouca altura, mas se agigantava no vigor com que conduzia a extração da dança na melhor entrega do corpo de bailarinos e bailarinas que ensinava. Dona Toshi (como a conheci de pequena) tinha olhos de fouetté… uma verdadeira chicotada aquele olhar.</p>
                      <p>&nbsp;É dessa transmissão que falo. É cheia de vida, de história, do imponderável que a arte proporciona e que não se apreende nos bancos de uma faculdade. Faz parte da cultura, daquilo que se cultiva e que é transmitido historicamente por meio de códigos e padrões de significados …tradição.</p>
                      <p>&nbsp;A palavra vem do latim traditio e significa entregar, passar adiante. A dança faz elo entre as gerações. Há um processo de escolha, apropriação e identidade. É preciso ter algo em si, para poder entregar.</p>
                      <p>&nbsp;Dentro dessa lógica, cada povo ou nação singulariza uma traço próprio que expressa em (suas) danças. O tango argentino, o flamenco espanhol, o samba brasileiro, etc.</p>
                      <p>&nbsp;O universo é vasto e a diversidade se impõe, de modo que um povo não dança uma só dança. Aqui, em terras tupiniquins de onde escrevo, vamos do ballet clássico, passando pelo contemporâneo, jazz, stiletto, axé, funk, samba, e há muito mais. Só em matéria de samba, podemos destacar o choro, o samba percussivo, o samba de roda, o samba no pé, o samba de gafieira, e tantos outros.</p>
                      <p>&nbsp;A bailarina Helen Ribeiro, que quando da última edição da nossa revista estava imersa em estratégias de arrecadação de fundos para poder levar sua gafieira ao Festival de Dança de Joinville de julho, conseguiu os valores necessários para estar lá. Ela é nossa entrevistada dessa vez e conta um pouco do que foi a experiência, bem como o que é ser bailarina no Brasil.</p>
                      <p>&nbsp;Convido-os a deixarem que as palavras façam a condução e que se deixem dançar e levar pelo texto, numa imersão até os bastidores dessa arte no nosso país na contemporaneidade, sob a perspectiva de quem trabalha por ela. O roteiro é de uma menina que sonhou, que se arriscou em um universo rigoroso quanto às exigências de um corpo padrão (Helen conhece o chicote!), que abraçou a identidade brasileira do samba de gafieira e que segue realizando.</p>
                      <p>&nbsp;Antes, contudo, às companheiras bailarinas, deixo uma breve mensagem: Dançar é uma experiência e desejo um feliz primeiro de setembro (com sabor de todo dia) para todas de nós que nos permitimos vivê-la: que não nos faltem eixo, linhas alongadas, colo de pé, e que resistam as unhas!</p>
                      <p><img loading="lazy" class="alignnone size-medium wp-image-17478" src="https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-300x234.jpg" alt="" width="300" height="234" srcset="https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-300x234.jpg 300w, https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-1024x797.jpg 1024w, https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-768x598.jpg 768w, https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE-1170x911.jpg 1170w, https://revistathebard.com/wp-content/uploads/2022/09/DANIELA-LAUBE.jpg 1242w" sizes="(max-width: 300px) 100vw, 300px"></p>
                      <p><a href="https://www.instagram.com/reel/CfRMqYNARkb/?igshid=YmMyMTA2M2Y%3D" role="link">Clique aqui para assistir</a></p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Pas De Deux</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p style="text-align: center;"><img loading="lazy" class="alignnone  wp-image-17484" src="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-300x300.png" alt="" width="213" height="213" srcset="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-300x300.png 300w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-150x150.png 150w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO.png 612w" sizes="(max-width: 213px) 100vw, 213px"></p>
                      <blockquote>
                      <p style="text-align: center;"><span style="font-size: 16px;"><strong>Helen Ribeiro <img loading="lazy" class="alignnone  wp-image-17316" src="https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-300x210.png" alt="" width="31" height="22" srcset="https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-300x210.png 300w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-1024x717.png 1024w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-768x538.png 768w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-1536x1075.png 1536w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-2048x1434.png 2048w, https://revistathebard.com/wp-content/uploads/2022/09/bandeira-do-brasil-1170x819.png 1170w" sizes="(max-width: 31px) 100vw, 31px"></strong></span></p>
                      <p style="text-align: center;"><span style="font-size: 16px;"><strong>Bailarina, Professora, Coreógrafa, Pesquisadora Licenciada em Dança UFMG</strong></span></p>
                      </blockquote>
                      <p>&nbsp;Iniciou seus estudos na Dança em 2002 com a dança do ventre e, em seguida, começou a fazer aulas de ballet em 2005 do qual continua até hoje. Já participou de vários espetáculos de Balé de repertório, além de ter sido bailarina principal nos espetáculos como “Raymonda” e “Harlequinade”. Participou diversas vezes do Festival de Dança em Joinville e de vários cursos e concursos, sendo premiada em dois deles. Trabalhou na Banda Just Beat It que fazia um tributo a Michael Jackson, depois fez parte da banda baile Bloco Show e, recentemente, foi integrante da Banda e Bloco de Carnaval Baianas Ozadas e Grupo de Dança Unibhfit. Já dançou no palco com nomes como Benjamim Abras Magary Lord, Moraes Moreira, Milena Jardim, entre outros. Já fez várias aulas, oficinas e cursos de Balé Clássico, Contemporâneo, Jazz, Vogue, Danças Urbanas, Dança de salão, Jazz Funk, Danças Populares e Ritmos.</p>
                      <p>&nbsp;Além de bailarina, atua como professora de dança, ensaiadora, personal dancer e coreografa ensaiando valsas de 15 anos, casamento, espetáculos de ballet e eventos em geral.</p>
                      <p>&nbsp;Atualmente faz aula de balé clássico, jazz funk (dança comercial), forró, samba, tango, bolero, samba e salsa. Trabalha como bailarina comercial dançando em desfiles de moda, blocos de carnaval, shows, projetos sociais, vídeo clipes (“Deus e o Diabo “-Das Quebradas”“.), escolas, hospitais e lares de idosos. Formada em Licenciatura em Dança na UFMG, tendo estudado várias técnicas corporais e abordagens somáticas, tendo desenvolvido uma pesquisa específica com Dança e terceira idade. Promove um projeto social intitulado “Bailarinando por sorrisos” em instituições com criança e idosos.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><strong><span style="font-size: 32px; color: #993300;">1</span></strong></p>
                      <p><strong><span style="color: #ffcc00;">THE BARD</span></strong> Como a dança entrou na sua vida e o que significa o festival de Joinville para você?</p>
                      <p><span style="color: #993300;"><strong>HELEN RIBEIRO</strong></span> A dança surgiu na minha vida desde que eu era criança. Sempre gostei de dançar e criar. Juntava minha família aos domingos e cobrava ingresso de R$ 0,50 para me assistirem apresentar. Comecei com aulas regulares de dança do ventre, por conta da novela “O Clone” e, depois, aos 10 anos, iniciei o ballet clássico, onde permaneço até os dias de hoje.</p>
                      <p>A minha escola sempre fazia excursões para Joinville e, aos 14 anos, pedi para ir. Era meu presente de 15 anos. Queria estar com meu grupo e vivenciar todas as experiências possíveis. Nessa época, estava muito apaixonada e envolvida com o ballet.</p>
                      <p>Depois disso, fui mais algumas vezes, porém sempre para fazer cursos e assistir danças. Nunca cogitei dançar nos palcos. Achava impossível ser selecionada no meio de tantas pessoas boas.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><strong><span style="font-size: 32px; color: #993300;">2</span></strong></p>
                      <p><strong><span style="color: #ffcc00;">THE BARD </span></strong>Quantas vezes você foi até o evento?</p>
                      <p><strong><span style="color: #993300;">HELEN RIBEIRO </span></strong>É a quinta vez que vou ao Festival de Joinville. Sendo a primeira vez que vou para dançar nos palcos.</p>
                      <p>Todas as demais vezes fui muito focada no ballet e, hoje, vou com a ideia mais ampla de aproveitar tudo, fazer cursos em novos estilos, e dançar o samba que é uma vertente da dança de salão que é uma das áreas em que sigo investindo.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #993300; font-size: 32px;"><strong>3</strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>THE BARD</strong></span> Qual a sensação de ser selecionada e quais as dificuldades de uma jovem artista para comparecer no evento?</p>
                      <p><strong><span style="color: #993300;">HELEN RIBEIRO </span></strong>A sensação é de que você é boa e capaz e as pessoas acreditam nisso, então: que tal você acreditar também?!</p>
                      <p>Por ter toda base clássica e fazer ballet praticamente a vida toda, sempre que ia em Joinville sempre estava com olhar exclusivo para essa modalidade (o ballet). No entanto, por ser uma dança para qual de fato não tenho tantas facilidades físicas, sempre achei que nunca seria aprovada (há muita gente boa e é uma dança muito restrita para determinados corpos).</p>
                      <p>Hoje, tendo a dança de salão como uma das minhas áreas de atuação, acreditei ter mais chances, por ser uma dança popular em que não há tantas restrições para os corpos.</p>
                      <p>Sendo assim, resolvi enviar o vídeo e, como o resultado demorou para sair, se tornou menor o tempo para juntar dinheiro para ir ao festival.</p>
                      <p>Então, a grande dificuldade, enquanto artista jovem e autônoma, é arrecadar dinheiro que custeie o básico da viagem, comida, hospedagem e avião.</p>
                      <p>Por ser um festival grande, tradicionalmente realizado em julho, período de férias, tudo se torna três vezes mais caro. Como artista e professora, ainda não alcancei o salário que mereço e que me permita viver tranquila. Principalmente, após ter ficada um tempo sem poder trabalhar pela pandemia. A classe artista foi muito afetada e ainda estou buscando espaços e oportunidades para fazer o que tanto amo: trabalhar com Dança.</p>
                      <p>Então, corri contra o tempo para juntar a grana para viajar.</p>
                      <p>Eu e meu partner implementamos várias formas de arrecadação: rifa, workshop, vaquinha e apresentações em baile.</p>
                      <p>As pessoas aderiram muito e nos ajudaram bastante. Cada um ajudando um pouquinho e dando muito apoio. Estamos muitos gratos.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #993300;"><strong><span style="font-size: 32px;">4</span></strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>THE BARD</strong></span> Como é seu trabalho com dança? Você pode falar um pouco da sua atuação junto a idosos?</p>
                      <p><span style="color: #993300;"><strong>HELEN RIBEIRO </strong></span>Sou artista, dançarina, bailarina, professora, coreógrafa, ensaiadora e pesquisadora.</p>
                      <p>Tenho diversas áreas de atuação.</p>
                      <p>Danço em eventos, shows, banda-baile, banda de carnaval (Baianas Ozadas), trabalho como personal dancer, coreografo para grupos, valsas de casamento e de debutante (15 anos), solistas e escolas de dança.</p>
                      <p>Trabalho como ensaiadora de coreografias de escola e pessoas físicas.</p>
                      <p>E, por fim, sou professora de ballet clássico para iniciantes e corpo de baile no ballet Yara Araújo, em Belo Horizonte, que é a escola em que me formei e minha família do coração. Estou junto com eles desde 2005 e, hoje, além de aluna, também sou professora.</p>
                      <p>Atualmente, também dou aula de dança para terceira idade em ILPS (instituições de longa permanência para idosos. É um trabalho democrático por trazer uma dança social e possível para todos os corpos.</p>
                      <p>Por ser formada em Licenciatura em Dança pela UFMG, desenvolvi meu TCC sobre dança e idosos, pesquisa que levo adiante nos dias atuais.</p>
                      <p><img loading="lazy" class="alignnone size-medium wp-image-17485" src="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-295x300.jpg" alt="" width="295" height="300" srcset="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-295x300.jpg 295w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-1007x1024.jpg 1007w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-768x781.jpg 768w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01-1170x1190.jpg 1170w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-01.jpg 1242w" sizes="(max-width: 295px) 100vw, 295px"></p>
                      <p><img loading="lazy" class="alignnone size-medium wp-image-17486" src="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-300x163.jpg" alt="" width="300" height="163" srcset="https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-300x163.jpg 300w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-1024x556.jpg 1024w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-768x417.jpg 768w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA-1170x635.jpg 1170w, https://revistathebard.com/wp-content/uploads/2022/09/HELEN-RIBEIRO-DANCA.jpg 1242w" sizes="(max-width: 300px) 100vw, 300px"></p>
                      <p><a href="https://www.instagram.com/reel/ChFE9figjk0/?igshid=YmMyMTA2M2Y%3D" role="link">Clique aqui para assistir</a></p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card>
          <div style="margin-bottom: 25vh;">
            <span><a class="linkGaleria" @click="goTop()">Topo</a></span>
          </div>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'EdicaoSetOut',
  components: {
    AppBar
  },
  data () {
    return {
      panel: [0],
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    voltar () {
      this.$router.back()
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
    goTop () {
      this.panel = []
    }
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
    .imgcenter {
      display: flex;
      flex-direction: row;
      align-items: center !important;
      justify-content: center !important;
    }
</style>
